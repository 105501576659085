@charset "UTF-8";
/* URL du logo à modifier */
/* Design login */
html {
  background: none; }

body {
  text-align: center; }

#login {
  padding-top: 20px; }

.login {
  position: relative;
  background: url(../../assets/images/icho-bg.png) center center no-repeat/cover;
  padding: 150px 0 80px 0; }
  .login h1 a {
    background: url("../../assets/images/logo-icho-blanc.svg") no-repeat center center/contain !important;
    width: 100%; }
    .login h1 a:focus {
      box-shadow: none; }
  .login a {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #e52321 !important;
    font-size: 14px;
    font-weight: 500; }
  .login form {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.21);
    text-align: left; }
    .login form input,
    .login form select,
    .login form textarea {
      border: 1px solid #fff;
      box-shadow: none; }
      .login form input:focus,
      .login form select:focus,
      .login form textarea:focus {
        border: 1px solid #fff;
        box-shadow: none; }
    .login form label {
      color: #fff;
      margin-bottom: 5px; }
  .login .button.button-large {
    padding: 0 0 40px;
    width: 100%;
    height: 30px;
    line-height: 40px; }
  .login .button-primary {
    transition: ease .5s;
    margin-top: 50px;
    box-shadow: none;
    padding: 15px;
    text-transform: uppercase;
    text-decoration: none;
    text-shadow: none;
    letter-spacing: 2px;
    color: #fff;
    font-weight: 500;
    border: none;
    text-transform: uppercase;
    background: #2458a5; }
    .login .button-primary:hover, .login .button-primary:active, .login .button-primary:focus {
      transition: ease .5s;
      box-shadow: none;
      text-decoration: none; }
  .login .message {
    border-left: 4px solid #fff; }
