/* URL du logo à modifier */
$logo-url : '../../assets/images/logo-icho-blanc.svg';
$rouge      	: #e52321;
$bleu       	: #2458a5;

/* Design login */

html {
    background: none;
}

body {
    text-align: center;
}

// scss-lint:disable all
#login {
    padding-top: 20px;
}
// scss-lint:enable all

.login {
    position: relative;
    background: url(../../assets/images/icho-bg.png) center center no-repeat / cover;
    padding: 150px 0 80px 0;
    h1 a {
        background: url($logo-url) no-repeat center center / contain !important;
        width: 100%;

        &:focus {
            box-shadow: none;
        }
    }

    a {
        padding-top: 25px;
        padding-bottom: 25px;
        color: $rouge !important;
        font-size: 14px;
        font-weight: 500;


    }

    // FORMULAIRE

    form {
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.21);
        text-align: left;
        input,
        select,
        textarea {
          border: 1px solid #fff;
          box-shadow: none;
            &:focus {
              border: 1px solid #fff;
              box-shadow: none;
            }
        }
        label{
          color: #fff;
          margin-bottom: 5px;
        }
    }

    // BOUTON DE CONNEXION

    .button.button-large {
        padding: 0 0 40px;
        width: 100%;
        height: 30px;
        line-height: 40px;
    }

    .button-primary {
        transition: ease .5s;
        margin-top: 50px;
        box-shadow: none;
        padding: 15px;
        text-transform: uppercase;
        text-decoration: none;
        text-shadow: none;
        letter-spacing: 2px;
        color: #fff;
        font-weight: 500;
        border: none;
      text-transform: uppercase;
      background: $bleu;
        &:hover,
        &:active,
        &:focus {
            transition: ease .5s;

            box-shadow: none;

            text-decoration: none;

        }
    }

    .message {
        border-left: 4px solid #fff;
    }
}
